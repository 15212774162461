import tinycolor from 'tinycolor2';
import {motion} from 'motion/react';
import {twMerge} from 'tailwind-merge';

type Props = {
  tabs: {text: string}[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  color?: string;
  id: string;
};

const LandingTabBar = ({tabs, activeIndex, setActiveIndex, color = 'white', id}: Props) => {
  return (
    <div className="flex max-w-[1000px] w-full mt-10 mb-10 overflow-auto px-[--side-padding] no-scrollbar">
      {tabs.map((tab, i) => (
        <button
          className={twMerge(
            'font-heading font-bold no-underline text-center text-xl h-[70px] flex justify-center items-center relative grow shrink-0 px-2 py-1 bg-transparent border-none transition hover:opacity-75 max-[700px]:text-base max-[700px]:h-14',
            activeIndex === i && 'hover:opacity-100'
          )}
          style={{color: activeIndex === i ? color : tinycolor(color).setAlpha(0.7).toRgbString()}}
          onClick={() => setActiveIndex(i)}
          key={tab.text}
        >
          <span className="shrink-0">{tab.text}</span>

          {activeIndex === i ? (
            <motion.div
              className="absolute w-full h-1 bottom-0 left-0 rounded-full"
              layoutId={`underline_${id}`}
              style={{backgroundColor: color}}
            />
          ) : null}
        </button>
      ))}
    </div>
  );
};

export default LandingTabBar;
