'use client';

import Image from 'next/image';
import {useState} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/file';
import {useLocale, useTranslations} from 'next-intl';
import {AnimatePresence, motion} from 'motion/react';
import {useInView} from 'react-intersection-observer';
import {HandWriting, SectionFeatureLeftSide, SectionTextSide, TextSideContent} from '../landingStyledComponents';
import {LandingPill, SectionTitle, WidthContainer} from './LandingComponents';
import DrawnArrow from '../../../public/images/twistedDrawnArrow.svg';
import {panelistaPrimaryColor} from 'src/utils/constants';
import InteractiveAvatar from './InteractiveAvatar';
import LandingButton from './LandingButton';
import RemixIcon from '../RemixIcon';
import Button from '../Button';

const SizeContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 288px;
`;

const AspectRatioContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 177.78%;
`;

const FeatureContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0px 14px 54px 0 rgba(21, 134, 140, 0.1);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
`;

const ShapeShadow = styled.div`
  background-color: #bce2e5;
  border-radius: 20px;
  position: absolute;
  height: 200px;
`;

const Avatar = styled.div`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  box-shadow: 0px 8px 30px 0 rgba(21, 134, 140, 0.1);
`;

const StyledContainer = styled(WidthContainer)`
  display: flex;
  min-height: 800px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const FeatureContentWrapper = styled(motion.div)`
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledDrawnArrow = styled(DrawnArrow)`
  position: absolute;
  top: -30px;
  right: 16%;
`;

const StyledHandwriting = styled(HandWriting)`
  position: absolute;
  top: -68px;
  right: -16%;
  transform: rotate(-4deg);
  color: ${panelistaPrimaryColor};

  @media (max-width: 430px) {
    right: -5%;
  }
`;

const playButtonStyle: React.CSSProperties = {
  width: 80,
  height: 80,
  position: 'absolute',
  backgroundColor: panelistaPrimaryColor,
  borderRadius: 40,
  left: 'calc(50% - 40px)',
  bottom: 30
};

const LandingPanelsSection = () => {
  const t = useTranslations();
  const locale = useLocale();
  const {ref, inView} = useInView();
  const [isPlaying, setPlaying] = useState(false);

  const videoUrl = `https://panelista.s3.nl-ams.scw.cloud/website/panelsVideo_${locale}.mp4`;

  const playVideo = () => {
    setPlaying(true);
    plausible('Panel example video played', {props: {place: 'Homepage', actor: 'Anders'}});
  };

  return (
    <StyledContainer>
      <SectionFeatureLeftSide>
        <SizeContainer>
          <AspectRatioContainer ref={ref}>
            <ShapeShadow style={{bottom: -32, width: '60%', left: '20%', borderRadius: 12, opacity: 0.5}} />
            <ShapeShadow style={{bottom: -16, width: '80%', left: '10%'}} />

            <FeatureContainer>
              <AnimatePresence initial={false}>
                {isPlaying ? (
                  <ReactPlayer
                    playing={isPlaying}
                    url={videoUrl}
                    height="100%"
                    width="100%"
                    playsinline
                    onEnded={() => setPlaying(false)}
                    style={{position: 'absolute', opacity: isPlaying ? 1 : 0, transition: 'opacity 0.3s'}}
                    controls
                  />
                ) : (
                  <FeatureContentWrapper initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                    <Image src="/images/landingPanelVideoPoster.jpg" fill alt="Panel video" sizes="300px" />
                    <Button style={playButtonStyle} onClick={playVideo} ariaLabel={t('common.play video')}>
                      <RemixIcon name="play-fill" color="white" size={40} />
                    </Button>
                  </FeatureContentWrapper>
                )}
              </AnimatePresence>
            </FeatureContainer>

            <Avatar style={{left: '16%', top: '-3%'}}>
              <InteractiveAvatar
                pictureSrc="/images/charlottaAvatar.jpg"
                altPictureSrc="/images/charlottaAltAvatar.jpg"
                size={70}
                alt="Charlotta"
                inView={inView}
              />
            </Avatar>
            <Avatar style={{right: '-8%', top: '20%'}}>
              <InteractiveAvatar
                pictureSrc="/images/jensaAvatar.jpg"
                altPictureSrc="/images/jensaAltAvatar.jpg"
                size={70}
                alt="Jensa"
                inView={inView}
              />
            </Avatar>
            <Avatar style={{right: '-6%', bottom: '24%'}}>
              <InteractiveAvatar
                pictureSrc="/images/joelAvatar.jpg"
                altPictureSrc="/images/joelAltAvatar.jpg"
                size={70}
                alt="Joel"
                inView={inView}
              />
            </Avatar>
            <Avatar style={{left: '-8%', bottom: '32%'}}>
              <InteractiveAvatar
                pictureSrc="/images/samAvatar.jpg"
                altPictureSrc="/images/samAltAvatar.jpg"
                size={70}
                alt="Sam"
                inView={inView}
              />
            </Avatar>

            <StyledDrawnArrow />
            <StyledHandwriting>{t('home.see video question')}</StyledHandwriting>
          </AspectRatioContainer>
        </SizeContainer>
      </SectionFeatureLeftSide>

      <SectionTextSide>
        <TextSideContent>
          <LandingPill>{t('common.panels')}</LandingPill>
          <SectionTitle className="my-8">{t('home.panels section title')}</SectionTitle>

          <p>{t.rich('home.panels section body', {strong: (chunks) => <strong>{chunks}</strong>})}</p>

          <LandingButton href="/panels" className="mt-7">
            {t('home.panels section cta')}
          </LandingButton>
        </TextSideContent>
      </SectionTextSide>
    </StyledContainer>
  );
};

export default LandingPanelsSection;
