import Script from 'next/script';

interface Props {
  touchpointId: string;
}

const RealWidget = ({touchpointId}: Props) => {
  return (
    <>
      <div className={`panelista-widget-${touchpointId}`} />
      <Script>
        {`[
          'https://panelista.com/embeds/widgets/${touchpointId}.js',
          'https://panelista.com/embeds/widget.js'
        ].forEach(function (src) {
          var script = document.createElement('script');
          script.setAttribute('data-panelista-id', '${touchpointId}');
          script.src = src;
          script.async = false;
          document.head.appendChild(script);
        });`}
      </Script>
    </>
  );
};

export default RealWidget;
